// @flow
import React, { lazy } from 'react';
import PrivateRoute from '@admin/helper/privateRoute';
import RoutesHandler from '@admin/helper/routesHandler';
import { useRecoilState } from 'recoil';
import { authDataState } from '@admin/store';
import LoadingPage from '@admin/components/loadingPage';
import { Navigate } from 'react-router-dom';

const General = lazy(() => import('./general'));
const Panel = lazy(() => import('./panel'));
const Auth = lazy(() => import('./auth'));


function AppRoutes(): React$Node {

	const [{ token }] = useRecoilState(authDataState);
	const routes = [
		{
			path: '*',
			component: <Navigate to={'/panel/dashboard'} replace />,
		},
		{
			path: '/general/*',
			component: <React.Suspense fallback={<LoadingPage />}>
				<General />
			</React.Suspense>,
		},
		{
			path: '/auth/*',
			component: (
				<React.Suspense fallback={<LoadingPage />}>
					<PrivateRoute isPrivate={!token} pathname={'/panel/dashboard'}>
						<Auth />
					</PrivateRoute>
				</React.Suspense>
			),
		},
		{
			path: 'panel/*',
			component: (
				<React.Suspense fallback={<LoadingPage />}>
					 <PrivateRoute isPrivate={token} pathname={'/auth/login'}>
						<Panel />
					 </PrivateRoute>
				</React.Suspense>
			),
		},
	];

	return (
		<RoutesHandler routes={routes} />
	);
}

export default AppRoutes;
