const languages = [
{ id: "english", value: "english", title: "English" },
{ id: "german", value: "german", title: "German" },
{ id: "persian", value: "persian", title: "Persian" },
{ id: "albanian", value: "albanian", title: "Albanian" },
{ id: "arabic", value: "arabic", title: "Arabic" },
{ id: "bengali", value: "bengali", title: "Bengali" },
{ id: "chinese", value: "chinese", title: "Chinese" },
{ id: "dutch", value: "dutch", title: "Dutch" },
{ id: "french", value: "french", title: "French" },
{ id: "greek", value: "greek", title: "Greek" },
{ id: "guarani", value: "guarani", title: "Guarani" },
{ id: "hindi", value: "hindi", title: "Hindi" },
{ id: "italian", value: "italian", title: "Italian" },
{ id: "korean", value: "korean", title: "Korean" },
{ id: "malay", value: "malay", title: "Malay" },
{ id: "portuguese", value: "portuguese", title: "Portuguese" },
{ id: "romanian", value: "romanian", title: "Romanian" },
{ id: "russian", value: "russian", title: "Russian" },
{ id: "serbo_croatian", value: "serbo_croatian", title: "Serbo-Croatian" },
{ id: "spanish", value: "spanish", title: "Spanish" },
{ id: "swahili", value: "swahili", title: "Swahili" },
{ id: "swedish", value: "swedish", title: "Swedish" },
{ id: "tamil", value: "tamil", title: "Tamil" },
{ id: "turkish", value: "turkish", title: "Turkish" },
];

export default languages;
