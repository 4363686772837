// @flow
import { Route, Routes } from 'react-router-dom';
import type { routesHandlerType } from '@admin/model/helper';

function RoutesHandler({ routes  = [] }:routesHandlerType): React$Node {
	return (
		<Routes>
			{routes.map((item) => {
				const { path, component, ...props } = item;
				return (
					<Route
						{...props}
						key={path}
						path={path}
						element={component}
					/>
				);
			},
			)}
		</Routes>
	);
}

export default RoutesHandler;
