import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
	key: 'appConfig',
});

const appConfigState = atom({
	key: 'appConfig',
	default: {
		isLight: true,
		lang: 'en',
	},
	effects_UNSTABLE: [persistAtom],
});

export default appConfigState;
