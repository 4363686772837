// @flow
import './style/app.scss';
import { useMemo, useEffect } from 'react';
import {
	createTheme,
	CssBaseline,
	Box,
	ThemeProvider, responsiveFontSizes,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { appConfigState, authDataState } from '@admin/store';
import { SnackbarUtilsConfigurator } from '@admin/helper/toster';
import { I18nextProvider, useTranslation } from 'react-i18next';
import AppRoutes from '@admin/routes';
import { getTheme } from '@admin/style/theme';
import i18nInstance from '@admin/i18n';

function App(): React$Node {
	// Create rtl cache
	const cacheRtl = createCache({
		key: 'muirtl',
		stylisPlugins: [rtlPlugin],
	});

	// Create ltr cache
	const cacheLtr = createCache({
		key: 'muiltr',
	});

	const [appConfig] = useRecoilState(appConfigState);

	const { i18n } = useTranslation();

	useEffect(() => {
		const htmlElement = document.querySelector('html');
		if (htmlElement) {
			htmlElement.setAttribute('dir', i18n.dir(appConfig.lang));
			htmlElement.setAttribute('lang', appConfig.lang);
		}
	}, [appConfig.lang, i18n]);

	const projectTheme = useMemo(
		() => {
			let theme = createTheme(getTheme(appConfig.isLight));
			theme = responsiveFontSizes(theme);
			return theme;
		},
		[appConfig.isLight],
	);

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
			},
		},
	});

	const navigate = useNavigate();
	const [authData] = useRecoilState(authDataState);
	useEffect(() => {
		if (!authData.token) {
			// TODO: check this condition
			// navigate('./auth/login');
		}
	}, [authData.token, navigate]);

	return (
		<I18nextProvider i18n={i18nInstance}>
			<QueryClientProvider client={queryClient}>
				<CacheProvider value={i18n.dir(appConfig.lang) === 'rtl' ? cacheRtl : cacheLtr}>
					<ThemeProvider theme={projectTheme}>
						<SnackbarProvider maxSnack={3}>
							<SnackbarUtilsConfigurator />
							<CssBaseline />
							<Box className={'viewBox'} component={'main'}>
								<AppRoutes />
							</Box>
						</SnackbarProvider>
					</ThemeProvider>
				</CacheProvider>
			</QueryClientProvider>
		</I18nextProvider>
	);
}

export default App;
