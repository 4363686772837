import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import reportWebVitals from './reportWebVitals';
import App from './App';

ReactDOM.render(
	// eslint-disable-next-line react/jsx-filename-extension
	<BrowserRouter>
		<RecoilRoot>
			<App />
			<RecoilNexus />
		</RecoilRoot>
	</BrowserRouter>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
