import { env } from '@admin/constant';

const fa = {

	copyrightUrl: env.REACT_APP_FUND_URL_EN,
	companyUrl: env.REACT_APP_COMPANY_URL_EN,
	copyright: 'Copyright ',
	designedAndDevelopedBy : 'designed & developed by ',
	required: '{{field}} را وارد کنید',
	persian: '{{field}} باید فارسی باشد',
	min: '{{field}} نمیتواند کمتر از {{min}} کاراکتر باشد',
	minNumber: '{{field}} نمیتواند کمتر از {{min}} باشد',
	max: '{{field}} نمیتواند بیشتر از {{max}} کاراکتر باشد',
	maxNumber: '{{field}} نمیتواند بیشتر از {{max}} باشد',
	length: '{{field}} باید {{length}} کاراکتر باشد',
	inValid: '{{field}} معتبر نیست',
};
export default fa;
