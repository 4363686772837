// @flow
import type { privateRouteType } from '@admin/model/helper';
import { Navigate } from 'react-router-dom';

function PrivateRoute({
	children,
	isPrivate = true,
	pathname = '.',
	otherwise,
}: privateRouteType): React$Node {
	if (!isPrivate){
		otherwise?.()
		return pathname && <Navigate to={{ pathname }} />
	}
	return children
}

export default PrivateRoute;
