// @flow
import type { envType } from '@admin/model/constant';

// eslint-disable-next-line dot-notation
const env: envType = {
	...process.env, ...window.env,
	isDevelopment: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
};

export default env;
