const en = {
	submit: 'Submit',
	back: 'Back',
	cancel: 'Cancel',
	ok: 'OK',
	nameLabel: 'Name:',
	infoLabel: 'Info:',
	emailLabel: 'Email:',
	roleLabel: 'Role:',
	verifiedLabel: 'Is verified:',
	apiKeyLabel: 'Api key:',
	activeLabel: 'Is active:',
	required: '{{field}} is required',
	titleLabel: 'Title:',
	summaryLabel: 'Summary:',
	yearLabel: 'Year:',
	isbnLabel: 'Isbn:',
	isPublishedLabel: 'Is published:',
	numberLabel: 'Number:',
	pageCountLabel: 'Page count:',
};
export default en;
